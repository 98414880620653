<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center bg-clay overflow-auto">
        
        <form @submit.prevent="onNewPasswordSubmit()">
            <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                {{error}}
            </div>
            
            <InputSingleline 
                v-model="password"
                label="Nytt passord"
                name="password"
                class="w-full "
                type="password"
                :required="true"
            />
            
            <button 
                type="submit" 
                class="button submit mb-4 w-full" 
                :class="{'loading' : loading}"
                :disabled="loading">
                
                Sett nytt passord
                <!-- {{$t('auth.setNewPassword')}} -->
            </button>
        </form>
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
        data() {
            return {
                error: null,
                password: null,
                loading: false,
                userToUpdatePassword: null,
            }
        },
        
        methods: {
            onNewPasswordSubmit(){
                console.log('onNewPasswordSubmit');
                // console.log('this.userToUpdatePassword', this.userToUpdatePassword);
                // console.log('this.password', this.password);
                this.newPasswordSubmit( this.userToUpdatePassword, this.password );
            },
        },
        
        mounted(){
            if (!this.$route.params.user) {
                this.$router.push({name: 'signin'});
            }
            
            this.userToUpdatePassword = this.$route.params.user;
        }
    }
</script>
